/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC220M
 * 화면 설명  : 시스템 접속 차단 공지 페이지
 */
<template>
  <ur-page-container class="msp" type="subpage" :show-title="false" title="공지 및 차단설정" >
    <ur-box-container direction="column" align-v="start" align-h="center" componentid=""  class="msp-bc-wrap">
      <!-- 공지 S -->
      <ur-box-container align-h="center" direction="row" class="bc-notice">
        <ur-box-container align-h="center" direction="column" class="notice-cont">
          <div class="notice-img"></div>
          <span class="title">{{this.Iv_SysInfo.bcAnucMngInfo.systmAnucTitl}}</span> 
          <div class="notice-text" v-html="Iv_SysInfo.bcAnucMngInfo.systmAnucCntnt">
          </div>
        </ur-box-container>
      </ur-box-container>
      <!-- 공지 E -->
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
// import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC220M',
  screenId: 'MSPBC220M',
  components: {},
  props: {
    params: {}
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // 로그인페이지(MSPBC600M)에서 '시스템차단공지조회'($commonUtil.getSysAnucCall) 하여 존재하는경우 호출 됨
    this.$BottomManager.fn_SetBottomVisible(false)
    console.log('this.$route.params=' + this.$route.params.isSysInfo)
    console.log('this.$route.params=' + this.$route.params.bcAnucMngInfo.systmAnucTitl)
    this.Iv_SysInfo = this.$route.params
    // console.log('this.Iv_SysInfo.bcAnucMngInfo.systmAnucTitl=' + this.Iv_SysInfo.bcAnucMngInfo.systmAnucTitl)
  },
  mounted() {

  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      Iv_SysInfo:{},
      Iv_isSysInfo:false
    }    
  },
  watch: {
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    }
};
</script>
